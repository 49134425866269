import Cookie from './cookie.js';
import { getParameterByName } from '../../base/utils.js';
import { isPublish, templateUrls } from '../../base/vars.js';
import SSO from '../components/sso.js';
import SSODelegate from '../components/sso-delegate.js';
import Login from '../../components/login.js';
import Join from '../components/join.js';
class Redirect {
    constructor() {
        if (isPublish) {
            this.handleRedirect();
        }
    }
    handleRedirect() {
        let requiresAuth = document.getElementsByTagName('html')[0].classList.contains('requireAuth'),
            requiresRedirect = document.getElementsByTagName('html')[0].classList.contains('redirectAuth'),
            authenticated = Cookie.isAuthenticated();

        var currentPagePath = sessionStorage.getItem('currentPagePath');
        if (currentPagePath !== null) {
            sessionStorage.removeItem("currentPagePath");
        }

        const urlWRJoin = templateUrls.joinPage;
        const urlWRProfileSetup = templateUrls.profileSetup;
        if (authenticated && window.location.href.includes(urlWRJoin)) {
            Join.clearCacheOnMobile();
        }
        if (authenticated && (getParameterByName('catalogOfOrigin') || getParameterByName('destinationLocation') || getParameterByName('ssoType') === '2')) {
            SSO.handleOutbound(window.location.href, false, true);
        } else if (authenticated && (getParameterByName('ssoType') === '1')) {
            SSODelegate.getPoints(window.location.href, false, true);
        } else if (requiresAuth && !authenticated) {
            if (!(window.location.href.includes(urlWRProfileSetup) && document.referrer.includes('/wyndham-rewards/auth-after-sso'))) {
                if (currentPagePath !== null) {
                    window.location.assign(Login.redirectToOktaLogin(currentPagePath));
                } else {
                    window.location.assign(Login.getOktaRedirectUriWithBrand());
                }
            }
        } else if (requiresRedirect && authenticated) {
            if (templateUrls.redirect) {
                window.location.assign(templateUrls.redirect);
            }
        }
    }
    deeplinkRedirect() {
        window.location.assign(getParameterByName('redirect'));
    }
}

export default new Redirect();
