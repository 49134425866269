class TextOffersWr {
    constructor() {
        document.querySelectorAll('.card-button').forEach((el) => {
            el.addEventListener('click', function(ev) {
                var pagePath = window.location.pathname;
                sessionStorage.setItem("currentPagePath", pagePath);
            });
        });
    }
}

export default new TextOffersWr();
