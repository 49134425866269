import { exists } from '../base/dom-utils.js';
import { manageFavoriteHotels, getServiceUrl } from '../base/utils.js';
import { _LOCALE_} from '../base/vars.js';
import RenderFavourite from '../components/render-favourite.js';
import CollectionOfHotels from './collection-hotels.js';
import Analytics from '../base/wyn-analytics-module.js';

class MyFavourite {
  constructor() {
    this.collectionOfHotels = new CollectionOfHotels();
    if (exists('.myFavourite')) {
      this.handlePageLoadAnalytics();
      this.hotelCollectionsData = [];
      this.imgData = [];
      this.favouriteHotels = [];
      this.sampleRes = [];
      this.getFavouriteHotels();
      this.handleBackClick();
    }
  }

  handlePageLoadAnalytics() {
    window.digitalData.page.pageInfo.pageName = 'favorites-list';
    window.digitalData.page.pageInfo.channel = 'my-account';

  }

  handleBackClick() {
    if($('.back-link').length > 0){
      $('.back-link').on('click', () => {
        history.back();
      });
    }
  }

  async fetchHotelCollectionData(propertyIds) {
    const callCollectionEndpoint = getServiceUrl('mySearchFavourite');
    const callCollection = `
      query sample($searchParameters: SearchParameters) {
        version
        searchProperties(searchParameters: $searchParameters) {
          totalCount
          properties {
            id
            propertyId
            name
            headline
            uniqueUrl
            address {
              city
              state
            }
            brand
            brandTier
            sabreId
          }
        }
          }
    `;
    const callCollectionVariables = {
      searchParameters: {
        language: _LOCALE_,
        limit: 500,
        offset: 0,
        propertyIds: propertyIds,
      },
    };

    try {
      const response = await fetch(callCollectionEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: callCollection,
          variables: callCollectionVariables,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseBody = await response.json();
      return responseBody;
    } catch (error) {
      throw error;
    }
  }

  async getFavouriteHotels() {
    let imgIdsArr = [];
    let requestObject = {
      collectionType : 'whg:image-category/override-tags/golf'
    };
    const isAuthenticated = RenderFavourite.getAuthenticatedState();
    const favouriteCardContainer = document.querySelector('#my-favourite-container');
    if (isAuthenticated === 'AUTHENTICATED') {
      const result = await manageFavoriteHotels('GET', null, 'FavoriteHotels');
      const myFavouriteContainer = document.getElementById('my-favourite-container');
      const myFavourites = document.getElementById('my-favourites');
      if(result.hasOwnProperty('FavoriteHotels') && result.FavoriteHotels.length > 0) {
        const propertyIds = result.FavoriteHotels.map((hotel) => hotel.PropertyId);
        const res = await this.fetchHotelCollectionData(propertyIds);
        let favouriteTitleEl = document.querySelector('.favourite-title-desc');
        const totalCount = res.data.searchProperties.totalCount;
        favouriteTitleEl.textContent = totalCount +  ' ' + favouriteTitleEl.textContent;
        this.favouriteHotels = res.data.searchProperties.properties;
        this.favouriteHotels.forEach((item) => {
          imgIdsArr.push({brandId: item.brand.toLowerCase(), propertyId: item.propertyId});
        });
        requestObject.properties = imgIdsArr;
        this.imgData = await this.collectionOfHotels.getImageTaggingData(requestObject);
        if (this.favouriteHotels.length > 0) {
          $('.no-favourite').css('display', 'none');
          $('#my-favourites').css('display', 'block');
          this.favouriteHotels.forEach((item) => {
            this.renderFavouriteCard(item, favouriteCardContainer);
          });
          myFavourites.appendChild(myFavouriteContainer);
          this.collectionOfHotels.handleQuickViewModal('.my-favourite', '.quick-view-button');
        }
      } else {
        $('.no-favourite').css('display', 'flex');
        $('#my-favourites').css('display', 'none');
        myFavourites.removeChild(myFavouriteContainer);
      }
      this.handleClickAnalytics();
    }
  }

  renderFavouriteCard(cardItem, container) {
    const cardListElement = document.createElement('div');
    cardListElement.classList.add('my-favourite-list');

    const cardImageElement = document.createElement('div');
    cardImageElement.classList.add('my-favourite-image');

    const carouselElement = document.createElement('div');
    cardImageElement.appendChild(carouselElement);
    RenderFavourite.fetchFavourite(cardImageElement, cardItem.propertyId);

    const cardDetailsElement = document.createElement('div');
    cardDetailsElement.classList.add('my-favourite-details');

    const headlineElement = document.createElement('h3');
    headlineElement.classList.add('headline-h');
    headlineElement.textContent = cardItem.name;
    cardDetailsElement.appendChild(headlineElement);

    const descriptionElement = document.createElement('div');
    descriptionElement.classList.add('my-favourite-description');

    const locationElement = document.createElement('p');
    locationElement.classList.add('location-text');
    locationElement.textContent = `${cardItem.address.city}, ${cardItem.address.state}`;
    descriptionElement.appendChild(locationElement);

    const ratingElement = document.createElement('div');
    const taIframe = `<iframe width="140px" height="60px" scrolling="no" style="border: none; overflow: hidden;" src="https://www.tripadvisor.com/WidgetEmbed-cdsLicensedRating?locationId=${cardItem.propertyId}&partnerId=699BC9B0A50349A389EF1084ECB2CA46&display=true"></iframe>`;
    ratingElement.classList.add('rating');
    const sanitizedtaIframe = DOMPurify.sanitize(taIframe);
    ratingElement.innerHTML = sanitizedtaIframe;
    descriptionElement.appendChild(ratingElement);

    const quickViewBtnElement = document.createElement('a');
    quickViewBtnElement.classList.add('btn-secondary', 'quick-view-button');
    quickViewBtnElement.href = DOMPurify.sanitize(cardItem.uniqueUrl);
    quickViewBtnElement.dataset.id = DOMPurify.sanitize(cardItem.propertyId);
    quickViewBtnElement.textContent = 'Quick View';

    cardDetailsElement.appendChild(descriptionElement);
    cardDetailsElement.appendChild(quickViewBtnElement);

    cardListElement.appendChild(cardImageElement);
    cardListElement.appendChild(cardDetailsElement);

    const imageUrls = this.imgData[cardItem.propertyId] || [];
    let imageUrl = {};
    if(imageUrls.length > 0) {
      imageUrl = imageUrls[0];
      const pictureElement = document.createElement('picture');

      const sourceTablet = document.createElement('source');
      sourceTablet.media = '(min-width: 768px)';
      sourceTablet.srcset = imageUrl.tablet;
      pictureElement.appendChild(sourceTablet);

      const sourceMobile = document.createElement('source');
      sourceMobile.media = '(max-width: 767px)';
      sourceMobile.srcset = imageUrl.mobile;
      pictureElement.appendChild(sourceMobile);

      const imageItem = document.createElement('img');
      imageItem.alt = imageUrl.altText;
      imageItem.src = imageUrl.desktop;
      imageItem.classList.add('pll-image-full-size', 'lazy-load-pageload', 'carousel-img', 'my-favourite-carousel-image');
      pictureElement.appendChild(imageItem);

      carouselElement.appendChild(pictureElement);
    }

    container.appendChild(cardListElement);
  }

  handleClickAnalytics() {
    if($('.no-favourite-container .start-search-button').length > 0){
      $('.no-favourite-container .start-search-button').on('click', () => {
        Analytics.satelliteTracker('startSearchingClick');
      });
    }
    const currentUrl = window.location.href;
    const firstSlashIndex = currentUrl.indexOf('/', currentUrl.indexOf('//') + 2);
    const baseUrl = currentUrl.substring(0,firstSlashIndex);
    const path = 'wyndham-rewards/my-favorite';
    const myFavUrl = `${baseUrl}/${path}`;
    if($(`a[href="${myFavUrl}"]`).length > 0){
      $(`a[href="${myFavUrl}"]`).on('click', function(event) {
        Analytics.satelliteTracker('myFavoritesClick');
      });
    }
  }
}

export default new MyFavourite();
